import React, { Fragment } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { Popover, Transition } from '@headlessui/react';

import { ChevronDownIcon } from '@heroicons/react/solid';
import { CheckCircleIcon, PhoneIcon, TruckIcon, GlobeIcon } from '@heroicons/react/outline';

const callsToAction = [
   // { name: 'Training Videos', href: '/trainingvideos', icon: PlayIcon },
   { name: 'Equipment', href: '/equipment', icon: TruckIcon },
   { name: 'Snow Services', href: '/snow', icon: CheckCircleIcon },
   { name: 'Other Services', href: '/otherservices', icon: GlobeIcon },
   { name: 'Contact', href: '/dirt#contact', icon: PhoneIcon },
];

function classNames(...classes) {
   return classes.filter(Boolean).join(' ');
}

export default function Menu() {
   const data = useStaticQuery(graphql`
      query {
         allDirtservice: allDirtservice {
            edges {
               node {
                  icon
                  id
                  link
                  name
                  brief
                  alt
               }
            }
         }
      }
   `);
   const dirts = data.allDirtservice.edges;

   return (
      <Popover className='z-50 relative'>
         {({ open }) => (
            <>
               <div className='bg-white max-w-7xl p-2 mx-auto flex flex-row justify-between items-center'>
                  <Link to='/'>
                     <StaticImage
                        width={96}
                        placeholder='blurred'
                        src='../images/Hunter-Environmental-Logo-Yellow-Fill.png'
                        alt='Hunter Environmental Logo'
                     />
                  </Link>
                  <div className='flex px-4 py-4 sm:px-6 lg:px-8'>
                     <Popover.Button
                        className={classNames(
                           open ? 'text-gray-900' : 'text-gray-500',
                           'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dirt'
                        )}
                     >
                        <span>Services</span>
                        <ChevronDownIcon
                           className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                           aria-hidden='true'
                        />
                     </Popover.Button>
                  </div>
               </div>

               <Transition
                  as={Fragment}
                  enter='transition ease-out duration-500'
                  enterFrom='opacity-0 -translate-y-1'
                  enterTo='opacity-100 translate-y-0'
                  leave='transition ease-in duration-150'
                  leaveFrom='opacity-100 translate-y-0'
                  leaveTo='opacity-0 -translate-y-1'
               >
                  <Popover.Panel className='absolute z-50 inset-x-0 transform shadow-lg'>
                     <div className='bg-white'>
                        <div className='max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16'>
                           {dirts.map((item) => (
                              <Link
                                 key={item.node.name}
                                 to={item.node.link}
                                 className='-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 transition ease-in-out duration-150'
                              >
                                 <div className='flex md:h-full lg:flex-col'>
                                    <div className='flex-shrink-0'>
                                       <div className='inline-flex items-center justify-center h-10 w-10 rounded-md bg-dirt  text-white sm:h-12 sm:w-12'>
                                          <img src={item.node.icon} alt={item.node.alt} className='object-fill h-6' />
                                       </div>
                                    </div>
                                    <div className='ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4'>
                                       <div>
                                          <p className='text-base font-medium text-gray-900'>{item.node.name}</p>
                                          <p className='mt-1 text-xs text-gray-500'>{item.node.brief}</p>
                                       </div>
                                       <p className='mt-2 text-sm font-medium text-dirt-dark lg:mt-4'>
                                          Learn more <span aria-hidden='true'>&rarr;</span>
                                       </p>
                                    </div>
                                 </div>
                              </Link>
                           ))}
                        </div>
                     </div>
                     <div className='bg-gray-50'>
                        <div className='max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8'>
                           {callsToAction.map((item) => (
                              <div key={item.name} className='flow-root'>
                                 <Link
                                    to={item.href}
                                    className='-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150'
                                 >
                                    <item.icon className='flex-shrink-0 h-6 w-6 text-gray-400' aria-hidden='true' />
                                    <span className='ml-3'>{item.name}</span>
                                 </Link>
                              </div>
                           ))}
                        </div>
                     </div>
                  </Popover.Panel>
               </Transition>
            </>
         )}
      </Popover>
   );
}
