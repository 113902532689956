import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

export default function DirtLinks() {
   const data = useStaticQuery(graphql`
      query {
         allDirtservice: allDirtservice {
            edges {
               node {
                  name
                  link
                  icon
                  alt
               }
            }
         }
      }
   `);
   const dirts = data.allDirtservice.edges;
   return (
      <div className='bg-dirt w-full text-white'>
         <div className='flex justify-between items-start max-w-7xl mx-auto px-3 md:px-4 lg:px-8'>
            {dirts.map((dirt) => (
               <div key={dirt.node.id} className='my-4 px-1 md:px-2 flex-1'>
                  <Link
                     to={dirt.node.link}
                     style={{ fontFamily: 'Muli' }}
                     className='flex flex-col items-center justify-between text-center max-h-full text-xxs font-light md:text-sm hover:underline text-black'
                  >
                     <div className='w-3 md:w-6 mb-2 flex items-start justify-start'>
                        <img src={dirt.node.icon} alt={dirt.node.alt} className='object-fill h-6 w-6' />
                     </div>
                     {dirt.node.name}
                  </Link>
               </div>
            ))}
         </div>
      </div>
   );
}
