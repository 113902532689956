import React from 'react';

import Menu from './DirtMenu';
import Footer from './footer';

import '../styles/global.css';
import '@fontsource/muli';

export default function SnowLayout({ children }) {
   return (
      <>
         <Menu />
         {children}
         <Footer bgColor='bg-dirt' textColor='text-black' footerColor='text-dirt' />
      </>
   );
}
